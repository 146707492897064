import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchWrapper } from "../helpers/fetch-wrapper";

/* Create slice */
const name = "message";
const initialState = createInitialState();
const extraActions = createExtraActions();
const slice = createSlice({ name, initialState });

/* Exports */
export const messageActions = { ...slice.actions, ...extraActions };
export const messageReducer = slice.reducer;

/* Implementation */
function createInitialState() {
  return {
    value: null,
  };
}

function createExtraActions() {
  const baseUrl = `${process.env.REACT_APP_API_URL}`;

  return {
    createMessageThread: createMessageThread(),
    postMessage: postMessage(),
    getThreadDetails: getThreadDetails(),
    getMessages: getMessages(),
    markAsRead: markAsRead(),
  };

  /* Create a message thread */
  function createMessageThread() {
    return createAsyncThunk(
      `${name}/createMessageThread`,
      async (request, { dispatch }) => {
        const result = await fetchWrapper.post(
          `${baseUrl}/create-thread`,
          request
        );
        return result;
      }
    );
  }

  /* Get booking by passing the confirmation code */
  function getThreadDetails() {
    return createAsyncThunk(
      `${name}/getThreadDetails`,
      async (request, { dispatch }) => {
        const result = await fetchWrapper.post(
          `${baseUrl}/thread-details`,
          request
        );
        return result;
      }
    );
  }

  /* Create a message thread */
  function postMessage() {
    return createAsyncThunk(
      `${name}/postMessage`,
      async (request, { dispatch }) => {
        const result = await fetchWrapper.post(
          `${baseUrl}/post-message`,
          request
        );
        return result;
      }
    );
  }

  /* Get booking by passing the confirmation code */
  function getMessages() {
    return createAsyncThunk(
      `${name}/postMessage`,
      async (request, { dispatch }) => {
        const result = await fetchWrapper.post(
          `${baseUrl}/get-message`,
          request
        );
        return result;
      }
    );
  }

  /* Get booking by passing the confirmation code */
  function markAsRead() {
    return createAsyncThunk(
      `${name}/markAsRead`,
      async (request, { dispatch }) => {
        const result = await fetchWrapper.post(
          `${baseUrl}/mark-as-read`,
          request
        );
        return result;
      }
    );
  }
}
