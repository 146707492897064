import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Welcome from "./pages/welcome";
import Checkedin from "./pages/checkedin";
import Checkingin from "./pages/checkingin";
import Reservationinfo from "./pages/reservationinfo";
import Privacypolicy from "./pages/privacypolicy";
import Termsofserevice from "./pages/termsofserevice";
import { PrivateRoute } from "./components/PrivateRoute";
import { CheckinRoute } from "./components/CheckinRoute";
import Home from "./pages/home";
import Common from "./pages/common";
import Cleaning from "./pages/cleaning";

global.dateFormat = "MM/DD/YY";
global.timeFormat = "hh:mm A";
global.dateTimeFormat = "MM/DD/YY[\r\n]hh:mm A";

function App() {
  const listingSlug = localStorage.getItem('listingSlug');
  return (
    <Router>
      <Routes>
        <Route exact path='/' element={<Home />} />
        <Route exact path="/:listingSlug" element={<Common />} />
        <Route exact path="/:listingSlug/:bookingSlug" element={<Common />} />
        <Route exact path='/:listingSlug/guestready' element={<Cleaning />} />

        {/* Private route start */}
        {/* <Route element={<PrivateRoute />}></Route> */}
        {/* private route end */}

        {/* Public route start */}
        {/* <Route exact path='/' element={<Home />} /> */}
        {/* <Route exact path="/:listingSlug" element={<Welcome />} /> */}
        {/* <Route exact path="/test" element={<Test />} /> */}
        {/* public route end */}

        {/* After confirmation code - Checkin route start */}
        {/* <Route element={<CheckinRoute />}>
          <Route path="/checkedin" element={<Checkedin />} />
          <Route path="/checkingin" element={<Checkingin />} />
          <Route path="/reservationinfo" element={<Reservationinfo />} />
          <Route path="/termsofserevice" element={<Termsofserevice />} />
          <Route path="/privacypolicy" element={<Privacypolicy />} />
        </Route> */}
        {/* Checkin route end */}

        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      <ToastContainer />
    </Router>
  );
}

export default App;
