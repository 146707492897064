import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchWrapper } from "../helpers/fetch-wrapper";
import HttpStatus from "./../helpers/status.enum";

/* Create slice */
const name = "checkin";
const reducers = createReducers();
const initialState = createInitialState();
const extraActions = createExtraActions();
const slice = createSlice({ name, initialState, reducers });

/* Exports */
export const checkInActions = { ...slice.actions, ...extraActions };
export const checkInReducer = slice.reducer;

/* Implementation */
function createInitialState() {
  return {
    bookingSlug: null
  };
}


function createReducers() {
  return {
    setBookingSlugCode
  };

  function setBookingSlugCode(state, action) {
    state.value = action.payload;
    state.bookingSlug = action.payload;
  }
}

function createExtraActions() {
  const baseUrl = `${process.env.REACT_APP_API_URL}/checkin`;

  return {
    getBookingByCode: getBookingByCode(),
    getAgrrements: getAgrrements(),
    emailVerification: emailVerification(),
    verifyOtp: verifyOtp(),
    getBookingInfo: getBookingInfo(),
  };

  /* To get booking detail by passing the request */
  function getBookingInfo() {
    return createAsyncThunk(
      `${name}/getBookingDetails`,
      async (request, { dispatch }) => {
        const result = await fetchWrapper.post(`${baseUrl}/booking-info`, request);
        if (result.status === HttpStatus.HTTP_OK) {
          const bookingSlug = result.result && result.result.booking_slug ? result.result.booking_slug : null;
          dispatch(checkInActions.setBookingSlugCode(bookingSlug));
        }
        return result;
      }
    );
  }

  /* Get booking by passing the confirmation code */
  function getBookingByCode() {
    return createAsyncThunk(
      `${name}/getBookingByCode`,
      async (request, { dispatch }) => {
        const { booking_slug = "", from_sms = false } = typeof request === "string" ? { booking_slug: request } : request;
        let bookingUrl = `${baseUrl}/${booking_slug}`;
        if(from_sms){
          bookingUrl += `?redirect_from_sms=${from_sms}`;
        }
        const result = await fetchWrapper.get(bookingUrl);
        /* Set confirmation code in redux */
        if (result.status === HttpStatus.HTTP_OK) {
          const bookingSlug = result.result && result.result.booking_slug ? result.result.booking_slug : null;
          // dispatch(checkInActions.setBookingSlugCode(bookingSlug));
        }
        return result;
      }
    );
  }

  /* To send the verification to user's registered email id */
  function emailVerification() {
    return createAsyncThunk(
      `${name}/emailVerification`,
      async (request) => await fetchWrapper.post(`${baseUrl}/email-verification`, request)
    );
  }

  /* To verify the user OTP */
  function verifyOtp() {
    return createAsyncThunk(
      `${name}/verifyOtp`,
      async (request) => await fetchWrapper.post(`${baseUrl}/verify-otp`, request)
    );
  }

  /* Get booking by passing the confirmation code */
  function getAgrrements() {
    return createAsyncThunk(
      `${name}/getAgrrements`,
      async (id, { dispatch }) => {
        const result = await fetchWrapper.get(`${baseUrl}/agreement-list/${id}`);
        return result;
      }
    );
  }


}
