import { PaymentElement } from "@stripe/react-stripe-js";
import { Form, Button } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import HttpStatus from "../helpers/status.enum";
import SpinnerLoader from "./SpinnerLoader";
import { earlyCheckin } from "../store/earlyCheckin.slice";
import { paymentActions } from "../store/payment.slice";
import { toast } from "react-toastify";

import { useElements, useStripe } from "@stripe/react-stripe-js";

const EarlyCheckoutForm = ({
  closeEarlyCheckinPaymentModel,
  selectedInterval,
  bookingSlug,
  paymentSlug,
  earlyLatePurchased,
  itemSlug,
  itemPayButtonStatus // 1 for request button, 2 for purchase button
}) => {
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {}, [selectedInterval]);

  const handlecheckinEarlyModalClose = () => {
    closeEarlyCheckinPaymentModel();
  };
  const [loader, setLoader] = useState(false);

  const [errorCardMessage, setErrorCardMessage] = useState(null);
  const handlepageLoaderModalOpen = async (event) => {
    setLoader(true);
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    
    const payementResult = await stripe.confirmPayment({
      elements,
      redirect: "if_required",
      // confirmParams: {
      //     return_url: "http://localhost:3000/reservationinfo",
      // },
    });

    if (payementResult.error) {
      setLoader(false);
      setErrorCardMessage(payementResult?.error?.message);

      console.log("Payement error message : ", payementResult?.error?.message);
      setTimeout(() => {
        setErrorCardMessage(null);
      }, 2000);
    } else {
      setErrorCardMessage(null);

      let paymentFormData = {
        payment_slug: paymentSlug,
        booking_slug: bookingSlug,
        final_payment_response: payementResult,
      };
      
      if (itemSlug) {
        paymentFormData.item_slug = itemSlug;
      }
      
      try {
        const resultPayment = await dispatch(
          paymentActions.updatePaymentStatus(paymentFormData)
        );
        if (resultPayment?.payload?.status === HttpStatus.HTTP_OK) {
          if(!itemSlug) {
            try {
              const formData = {
                booking_slug: bookingSlug,
                time_interval: selectedInterval,
              };
              const result = await dispatch(
                earlyCheckin.updatedCheckinCheckoutTime(formData)
              );
            } catch (error) {
              console.error(
                "Unable to update booking checkin checkout time : ",
                error
              );
            }
          }
          if(itemSlug){            
            toast(resultPayment?.payload?.message);
          }
            
        }
      } catch (error) {
        console.error("Error occurred while updating payment status :", error);
      }
      handlecheckinEarlyModalClose();
      setLoader(false);
      if(!itemSlug){ // No Need to refresh page if customUpsells purchased
        earlyLatePurchased(true); // User to refresh the page after early checkin payment..
      }
    }
  };

  return (
    <>
      <Form>
        <div className="paymentmethod pt-0 pb-2">
          <PaymentElement />
        </div>
        {errorCardMessage ? (
          <small className="text-danger mb-3 d-block">{errorCardMessage}</small>
        ) : (
          ""
        )}

        <hr className="mb-3 mt-3" />
        <div className="d-flex justify-content-between">
          <Button
            className="outlinebutton"
            onClick={() => handlecheckinEarlyModalClose(false)}
          >
            Cancel
          </Button>
          <Button
            className="fillbutton"
            onClick={handlepageLoaderModalOpen}
            disabled={loader ? true : false}
          >
            {loader ? (
              <>
                <SpinnerLoader />
              </>
            ) : (
              itemSlug ? (itemPayButtonStatus === 1 ? "Request" : "Purchase") : "Submit"
            )}
          </Button>
        </div>
      </Form>
    </>
  );
};

export default EarlyCheckoutForm;
