export const convertUTCToLocalTime = (utcTime) => {
  const currentDate = new Date().toISOString().split("T")[0];
  const utcDate = new Date(`${currentDate}T${utcTime}Z`);

  const localTime = utcDate.toLocaleTimeString(undefined, {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });

  return localTime;
};

export const getCurrentUTCTime = () => {
  const now = new Date();
  const hours = String(now.getUTCHours()).padStart(2, "0");
  const minutes = String(now.getUTCMinutes()).padStart(2, "0");
  const seconds = String(now.getUTCSeconds()).padStart(2, "0");
  return `${hours}:${minutes}:${seconds}`;
};

export const generateSlug = (digit = 12) => {
  const characters = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  let slug = "";
  for (let i = 0; i < digit; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    slug += characters[randomIndex];
  }
  return slug;
};

export const getSubstring = (str, length = 20) => {
  return str.substring(0, length);
};

export const currentDate = () => {
  const today = new Date();
  const formattedDate = `${today.getFullYear()}-${String(
    today.getMonth() + 1
  ).padStart(2, "0")}-${String(today.getDate()).padStart(2, "0")}`;
  return formattedDate;
};

export const convertDateFormat = (dateString) => {
  const [year, month, day] = dateString.split('-');
  const shortYear = year.slice(-2);
  return `${month}/${day}/${shortYear}`;
};

export const commonRenderTextWithLinks = (text, fromUpsells = true) => {
  const linkRegex = /(https?:\/\/[^\s]+|www\.[^\s]+)/g; // Updated regex to match URLs starting with www
  const parts = text?.split(linkRegex);

  const handleAnchorTag = (e) => {
    if(!fromUpsells) {
      e.stopPropagation();
    } 
  }

  return parts.map((part, index) => {
    if (linkRegex.test(part)) {
      // Regex to capture trailing punctuation (like ., ?, !, etc.)
      const lastCharRegex = /[-.,=!?;:"')]*$/;
      const lastCharMatch = part.match(lastCharRegex);
      // If we have trailing punctuation, isolate it
      const lastChar = lastCharMatch ? lastCharMatch[0] : "";
      // Remove the trailing punctuation from the URL
      const url = lastChar ? part.slice(0, -lastChar.length) : part;
      const href = url.startsWith('www') ? `http://${url}` : url; // Add http:// to URLs starting with www
      return (
        <>
          <a key={index} onClick={handleAnchorTag} href={href} target="_blank" rel="noopener noreferrer">
            {url}
          </a>
          {lastChar}
        </>
      );
    }
    return part;
  });
};