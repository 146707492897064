import { Form, Button } from "react-bootstrap";
import { Link, useParams, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import HttpStatus from "./../helpers/status.enum";
import { checkInActions } from "./../store/checkin.slice";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import React, { useEffect, useState } from "react";
import Datepicker from "../assets/components/datepicker";
import Moment from "moment";
import SpinnerLoader from "../components/SpinnerLoader";
import {
  generateSlug,
} from "../config/functions";


function Welcome({ checkedInPageHandle, checkingPageHandle, reservationPageHandle }) {
  const dispatch = useDispatch();
  const { listingSlug, bookingSlug } = useParams();
  localStorage.setItem('listingSlug', listingSlug);
  const location = useLocation();

  const [checkinDate, setCheckinDate] = useState(null);
  const [checkoutDate, setCheckoutDate] = useState(null);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

   // Function to parse query parameters
   const getQueryParams = (search) => {
    return new URLSearchParams(search);
  };

  useEffect(() => {
    const queryParams = getQueryParams(location.search);
    const checkin = queryParams.get('checkin');
    const checkout = queryParams.get('checkout');
    if(bookingSlug){
      fetchData();
    }

    if (checkin && checkout) {
      setCheckinDate(new Date(checkin));
      setCheckoutDate(new Date(checkout));
    }
  }, [location.search]);

  const onCheckinDateChange = (date) => {
    setCheckinDate(date);
  };

  const onCheckoutDateChange = (date) => {
    setCheckoutDate(date);
  };

  const onCheckinDateClear = () => {
    setIsDisabled(true);
    setCheckinDate(null);
  }

  const onCheckoutDateClear = () => {
    setIsDisabled(true);
    setCheckoutDate(null);
  }

  useEffect(() => {
    if (checkinDate !== null && checkoutDate !== null) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [checkinDate, checkoutDate]);

  const submitHandler = async (event) => {
    event.preventDefault();
    setIsDisabled(true);
    setIsLoading(true);
    const formData = {
      "listing_slug": listingSlug,
      "checkin_date": Moment(checkinDate).format("YYYY-MM-DD"),
      "checkout_date": Moment(checkoutDate).format("YYYY-MM-DD")
    };

    const result = await dispatch(
      checkInActions.getBookingInfo(formData)
    );
    const data = result?.payload || null;

    if (data?.status === HttpStatus.HTTP_OK) {
      if (!localStorage.getItem('system-id')) {
        localStorage.setItem('system-id', generateSlug(20));
      }
      const records = data?.result;
      if (records?.isCheckinCompleted === true) {
        reservationPageHandle(true);
        checkingPageHandle(false);
        checkedInPageHandle(false);
        // navigate("/reservationinfo");
      } else if (records?.is_otp_verified === 1) {
        if (records?.is_guest_form_bypass === 1) {
          reservationPageHandle(true);
          checkingPageHandle(false);
          checkedInPageHandle(false);
          // navigate("/reservationinfo");
        } else {
          checkingPageHandle(true);
          checkedInPageHandle(false);
          // navigate("/checkingin");
        }
      } else {
        checkedInPageHandle(true);
        // navigate("/checkedin");
      }
    } else {
      checkedInPageHandle(false);
      toast(data?.message);
      setIsDisabled(false);
      setIsLoading(false);
    }
  };

  const fetchData = async () => {
    if(bookingSlug){
      const formData = {
        "booking_slug": bookingSlug,
        "from_sms" : true
      }
      const result = await dispatch(checkInActions.getBookingByCode(formData));
      setCheckinDate(result?.payload?.result?.checkin_date);
      setCheckoutDate(result?.payload?.result?.checkout_date);
    }
  }

  return (
    <section className="welcomePage">
      <div className="content">
        <div className="w-100 p-2">
          <h1 id="welcometext"></h1>
          <h4>You're gonna have a great stay :)</h4>
          <Form onSubmit={submitHandler}>
            <Form.Group className="mb-3 mt-4" controlId="checkInDate">
              <Datepicker onCheckinDateChange={onCheckinDateChange} onCheckoutDateChange={onCheckoutDateChange} onCheckinDateClear={onCheckinDateClear} onCheckoutDateClear={onCheckoutDateClear} paramCheckinDate={checkinDate} paramCheckoutDate={checkoutDate} />
            </Form.Group>
            <Button
              className="fillbuttonwhite"
              type="submit"
              disabled={isDisabled ? true : false}
            >
              {isLoading ? (<><SpinnerLoader /></>) : ("Begin Check In")}
            </Button>
          </Form>
          {/* <h6 className="text-center mt-3"><Link to="#" className="text-overline2 text-white">Have you confirmation code?</Link></h6> */}
        </div>
      </div>
      <div className="contentFooter">
        <h6>
          Powered by{" "}
          <Link to="#" className="text-overline">
            Happy Guest
          </Link>
        </h6>
      </div>
      {/* <div className="welcomebg">
        <div className="overlay"></div>
      </div> */}
      <div className="welcomebgvideo">
        <video autoPlay muted playsInline loop id="video">
          <source src={process.env.REACT_APP_AWS_CLOUD_FRONT_URL+"/Stream_Videos/welcome-bg.mp4"} type="video/mp4" />
          {/* <source src="https://d2rweuuav95dhv.cloudfront.net/Stream_Videos/welcome-bg.mp4" type="video/mp4" /> */}
          {/* <source src="https://d2rweuuav95dhv.cloudfront.net/video/welcome-bg.ogg" type="video/ogg" /> */}
          {/* <source src="https://d2rweuuav95dhv.cloudfront.net/video/welcome-bg-new.webm" type="video/webm" /> */}
        </video>
      </div>
      <div className="welcomebgvideomobile">
        <video autoPlay muted playsInline loop id="video">
          {/* <source src="https://d2rweuuav95dhv.cloudfront.net/Stream_Videos/welcome-bg.mp4" type="video/mp4" /> */}
          {/* <source src="https://d2rweuuav95dhv.cloudfront.net/Stream_Videos/welcome-bg.mp4" type="video/mp4" /> */}
          {/* <source src="https://d2rweuuav95dhv.cloudfront.net/video/welcome-bg.ogg" type="video/ogg" /> */}
          {/* <source src="https://d2rweuuav95dhv.cloudfront.net/video/welcome-bg-new.webm" type="video/webm" /> */}
          {/* <source src="https://d2rweuuav95dhv.cloudfront.net/Stream_Videos/welcome-bg-1.webm" type="video/webm" /> */}
          <source src={process.env.REACT_APP_AWS_CLOUD_FRONT_URL+"/Stream_Videos/welcome-bg.mp4"} type="video/mp4" />
        </video>
      </div>
    </section>
  );
}

export default Welcome;
