import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useLayoutEffect,
} from "react"; // Import React and useState
import { Button, Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import paymentmethodImg from "../assets/images/payment-method.png";
import "react-phone-input-2/lib/style.css";
import cornerupRight from "../assets/images/corner-up-right.svg";
import EarlyCheckinImg from "../assets/images/early-check.svg";
import { Link } from "react-router-dom";

import Moment from "moment";

import HttpStatus from "./../helpers/status.enum";
import { useSelector, useDispatch } from "react-redux";
import { checkInActions } from "./../store/checkin.slice";
import { onboardingActions } from "../store/onboarding.slice";
import SpinnerLoader from "../components/SpinnerLoader";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// import required modules
import { Pagination, Navigation } from "swiper/modules";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { commonActions } from "../store/common.slice";
import { earlyCheckin } from "../store/earlyCheckin.slice";
import { paymentActions } from "./../store/payment.slice";
import EarlyCheckoutForm from "../components/EarlyCheckoutForm";
import { toast } from "react-toastify";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useParams } from "react-router-dom";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import { messageActions } from "../store/message.slice";
import sendMessageIcon from "../assets/images/send-meesage-icon.svg";
import chatIcon from "../assets/images/chat-icon.gif";
import CustomMessageLoader from "../components/MessageLoader";
import { commonRenderTextWithLinks } from "../config/functions";
import {
  convertUTCToLocalTime,
  getCurrentUTCTime,
  generateSlug,
  currentDate,
  convertDateFormat,
} from "../config/functions";
import UpgradeItems from "../components/UpgradeItems";

const stripePromiseForEarlyCheckin = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
);
const encryKey = process.env.REACT_APP_STRIPE_ENCRYPT_KEY; // Must be 16 bytes for AES-128

function Reservationinfo() {
  const { listingSlug } = useParams();
  const [showcheckinEarlyModal, setShowcheckinEarlyModal] = useState(false);
  const [pageLoaderModal, setPageLoaderModal] = useState(true);
  const [isChatEnabled, setIsChatEnabled] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setPageLoaderModal(false); // Hide after 3 seconds
      if(isChatEnabled){
        createMessageThread()
      }
    }, 3000);
    return () => clearTimeout(timer);
  }, [isChatEnabled]);

  const dispatch = useDispatch();
  const [records, setData] = useState(null);
  const [listingCms, setListingCms] = useState(null);
  const [listingLocals, setListingLocals] = useState(null);
  const [itemsList, setItemsList] = useState(null);
  const [listingHost, setListingHost] = useState(null);
  const [isCheckedOut, setIsCheckedOut] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isWaiver, setIsWaiver] = useState(0);
  const [isDataEnabled, setIsDataEnabled] = useState(false);
  const [isTodayCheckedOut, setIsTodayCheckedOut] = useState(false);
  const [agreementItems, setAgreementList] = useState(false);
  const [cmsContent, setCmsContent] = useState(null);

  const [isAdShow, setIsAdShow] = useState(null);
  const [isPurchased, setIsPurchased] = useState(null);
  const [isCtaShow, setIsCtaShow] = useState(null);
  const [cardTitle, setCardTitle] = useState(null);
  const [cardBody, setCardBody] = useState(null);
  const [timeInterval, setTimeInterval] = useState([]);
  const [firstInterval, setFirstIntervalValue] = useState("");
  const [selectedTimeInterval, setSelectedTimeInterval] = useState("");

  const [isIntentCreate, setIsIntentCreate] = useState(false);
  const [clientSecret, setClientSecret] = useState(false);
  const [isIntentModelShow, setIsIntentModelShow] = useState(false);
  const [loader, setLoader] = useState(false);
  const [customerId, setCustomerId] = useState("");
  const [stripeId, setStripeId] = useState("");
  const [paymentSlug, setPaymentSlug] = useState("");
  const [newCheckinTime, setNewCheckinTime] = useState("");
  const [isStateRefresh, setIsStateRefresh] = useState(false);
  const [isDesabled, setIsDesabled] = useState(false);
  const [isFreeCheckout, setIsFreeCheckout] = useState(false);
  const [needToEmailName, setNeedToEmailName] = useState(false);
  const [checkoutName, setCheckoutName] = useState("");
  const [checkoutEmail, setCheckoutEmail] = useState("");

  //START:Messaging state
  const [showMessageModal, setShowMessageModal] = useState(false);
  const messageRecords = useRef([]);
  const [messageBody, setMessageBody] = useState("");
  const [page, setPage] = useState(1);
  const [isNewThread, setIsNewThread] = useState(false);
  const threadRecords = useRef(false);
  const messageEndRef = useRef(null);
  const [socket, setSocket] = useState(null);
  const [isNewMessage, setIsNewMessage] = useState(false);

  // Added By Gautam
  const perPage = 2;
  const chatContainerRef = useRef(null); // Ref to the chat container
  const scrollHeightBeforeRef = useRef(0);
  const currentScrollTopRef = useRef(0);
  const [loading, setLoading] = useState(false); // State to manage loading state
  const [fetchMessageFlag, setFetchMessageFlag] = useState(true);
  const [isMsgSend, setisMsgSend] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [imageSrc, setImageSrc] = useState(
    threadRecords.current?.host_profile_path || chatIcon
  );
  const [messageComingLoading, setMessageComingLoading] = useState(false);
  const [sendDisable, setSendDisable] = useState(false);
  const [keyboardOpen, setKeyboardOpen] = useState(false);
  const [modalAnimation, setModalAnimation] = useState(
    "modal-dialog-animation"
  );
  const [sendMessageAnimation, setSendMessageAnimation] = useState(
    "send-message-animation"
  );
  const inputRef = useRef(null);
  const timeoutSetRef = useRef(false);
  const [currentTime, setCurrentTime] = useState(null);
  const [timer, setTimer] = useState(null);
  const closeButtonRef = useRef(null);
  const isModalClosing = useRef(null);

  const handleExpandClick = (event, focusOnInput = false) => {
    if (focusOnInput) {
      setIsExpanded(true);
      setKeyboardOpen(true);
    } else {
      setIsExpanded((prevState) => !prevState); // Toggle the state
    }
  };

  const handleBlur = (e) => {
    if (
      isModalClosing.current ||
      closeButtonRef.current.contains(e.relatedTarget)
    ) {
      return;
    }
    setKeyboardOpen(false);
  };
  // Ended By Gautam

  //END:Messaging state

  // Scroll to top when component mounts or navigates
  useEffect(() => {
    if (!pageLoaderModal) {
      // Find the position of the "Checking In" section relative to the top of the document
      const checkingInSection = document.getElementById("reservationinfo");
      const sectionPosition = checkingInSection.getBoundingClientRect().top;

      // Scroll the page to the position of the "Checking In" section
      window.scrollTo({
        top: sectionPosition,
        behavior: "smooth",
      });
    }
  }, []);

  // swiper
  const [setSwiperRef] = useState(null);

  const bookingSlug = useSelector((data) => {
    return data.checkIn.value;
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await dispatch(
          checkInActions.getBookingByCode(bookingSlug)
        );

        if (result?.payload?.status === HttpStatus.HTTP_OK) {
          setData(result?.payload?.result);
          setIsCheckedOut(result?.payload?.result?.is_checked_out);
          setIsWaiver(result?.payload?.result?.deposit_option);
          setIsDataEnabled(result?.payload?.result?.isDataEnabled);
          setIsTodayCheckedOut(result?.payload?.result?.isTodayCheckedOut);
          setListingCms(result?.payload?.result?.listing);
          setCmsContent(result?.payload?.result?.listing_alert);
          setNeedToEmailName(result?.payload?.result?.needToEmailName);
          if (result?.payload?.result?.is_chat_enable === 1) {
            // Check if chat is enabled
            // createMessageThread();
            setIsChatEnabled(true)
          }
          const listingSlug = result?.payload?.result?.listing?.slug;
          const formData = {id: listingSlug, booking_slug: bookingSlug};
          const listingCmsRes = await dispatch(
            onboardingActions.getListingCmsInfo(formData)
          );
          if (listingCmsRes?.payload?.status === HttpStatus.HTTP_OK) {
            setListingLocals(listingCmsRes?.payload?.result?.listing_local);
            setListingHost(listingCmsRes?.payload?.result?.listing_host);
            setItemsList(listingCmsRes?.payload?.result?.items);
            // setCmsContent(listingCmsRes?.payload?.result?.listing_alert);
          }
          getAgreementList();
          /*const cmsResult = await dispatch(commonActions.getCmsContents());
          if (cmsResult?.payload?.status === HttpStatus.HTTP_OK) {
            setCmsContent(cmsResult?.payload?.result);
          }*/
          getAdvertisement();
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    // Call the fetchData function inside useEffect
    fetchData();
  }, [isStateRefresh]);

  // Function to start the loader timer
  const startLoaderTimer = () => {
    const timerId = setTimeout(() => {
      setMessageComingLoading(false); // Hide the loader after 10 seconds if data isn't loaded
      setSendDisable(false);
    }, 15000); // 15 seconds timeout
    setTimer(timerId); // Store the timer reference
  };

  useEffect(() => {
    if (!messageComingLoading && timer) {
      clearTimeout(timer);
    }
  }, [messageComingLoading]);

  const markAsCheckedOut = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    const formData = {
      booking_slug: bookingSlug,
    };
    try {
      const result = await dispatch(
        onboardingActions.markAsCheckedOut(formData)
      );
      if (result?.payload?.status === HttpStatus.HTTP_OK) {
        setIsCheckedOut(true);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error occurred while submitting form:", error);
    }
  };

  const downloadReceipt = async (event) => {
    event.preventDefault();
    const eventType = parseInt(
      event.currentTarget.getAttribute("data-eventtype")
    );
    let queryString = "";
    if (eventType === 1) {
      queryString =
        "path=" + agreementItems?.stripe_receipt_url + "&file_name=Receipt";
    } else if (eventType === 2) {
      queryString =
        "path=" +
        agreementItems?.waiver_agreement_url +
        "&file_name=DamageWaiver";
    }
    try {
      let endPoint = "/download-file" + (queryString ? "?" + queryString : "");
      let filePath = process.env.REACT_APP_API_ENDPOINT + endPoint;
      window.location.href = filePath;
    } catch (error) {
      setIsLoading(false);
      console.error("Error occurred while submitting form:", error);
    }
  };

  const [value, setValue] = useState(0);

  const handleChange = (e) => {
    setValue(parseInt(e.target.value));
  };

  const getAdvertisement = async (event) => {
    const formData = {
      listing_slug: listingSlug,
      booking_slug: bookingSlug,
    };
    const advertisementResult = await dispatch(
      earlyCheckin.getAdvertisement(formData)
    );

    if (advertisementResult?.payload?.status === HttpStatus.HTTP_OK) {
      const advertisementData = advertisementResult?.payload?.result;
      const adTimeInterval = advertisementData?.time_interval;
      // Convert the object into an array
      const adArray = Object.entries(adTimeInterval).map(([key, value]) => ({
        label: value,
        value: key,
      }));
      const intervalValue = adArray[0] ? adArray[0]?.value : "";
      setFirstIntervalValue(intervalValue);
      setSelectedTimeInterval(adArray[0]?.label);
      setTimeInterval(adArray);
      setIsAdShow(advertisementData?.isAdShow);
      setIsPurchased(advertisementData?.isPurchased);
      setIsCtaShow(advertisementData?.isCtaShow);
      setCardTitle(advertisementData?.card_title);
      setCardBody(advertisementData?.card_body);
      setNewCheckinTime(advertisementData?.checkin_time);
    }
  };

  const earlyLatePurchased = async (event) => {
    getAdvertisement();
    setIsStateRefresh(true);
  };

  const openEarlyCheckinPaymentModel = () => {
    setShowcheckinEarlyModal(true);
    if (needToEmailName) {
      setIsDesabled(true);
    }
  };

  const handleSelectChange = (e) => {
    setFirstIntervalValue(e?.value);
    setSelectedTimeInterval(e?.label);
    setIsDesabled(true);
    if (e?.value != "free") {
      if (needToEmailName) {
        const isEmailValid = validateEmail(checkoutEmail);
        if (checkoutName.length > 2 && isEmailValid) {
          setIsDesabled(false);
        }
      } else {
        setIsDesabled(false);
      }
      setIsFreeCheckout(false);
    } else {
      if (needToEmailName) {
        const isEmailValid = validateEmail(checkoutEmail);
        if (checkoutName.length > 2 && isEmailValid) {
          setIsDesabled(false);
        }
      } else {
        setIsDesabled(false);
      }
      setIsFreeCheckout(true);
    }
  };

  const createPaymentIntent = async (event) => {
    setLoader(true);
    setClientSecret(false);
    setIsIntentCreate(false);
    setIsIntentModelShow(false);
    let formData = {
      booking_slug: bookingSlug,
      intent_type: 3, // Conditional assignment
      needToEmailName: needToEmailName,
      checkout_name: checkoutName,
      checkout_email: checkoutEmail,
      time_interval: firstInterval
    };
    const result = await dispatch(paymentActions.createPaymentIntent(formData));
    const responses = result?.payload;
    if (responses?.status === HttpStatus.HTTP_OK) {
      const paymentRes = responses.result;
      setClientSecret(paymentRes?.client_secret);
      setCustomerId(paymentRes?.customer_id);
      setStripeId(paymentRes?.id);
      setPaymentSlug(paymentRes?.payment_slug);
      setLoader(false);
      setIsIntentCreate(true);
      setIsIntentModelShow(true);
      setNeedToEmailName(false); // When user already filed name and email not asked again
    } else {
      toast(responses?.message);
      setLoader(false);
      setIsIntentModelShow(false);
    }
  };

  const updatedCheckinCheckoutFreeTime = async () => {
    try {
      setLoader(true);
      const formData = {
        booking_slug: bookingSlug,
        time_interval: firstInterval,
        needToEmailName: needToEmailName,
        checkout_name: checkoutName,
        checkout_email: checkoutEmail,
      };
      const result = await dispatch(
        earlyCheckin.updatedCheckinCheckoutTime(formData)
      );
      if (result?.payload?.status === HttpStatus.HTTP_OK) {
        setIsStateRefresh(true);
        setShowcheckinEarlyModal(false);
        setLoader(false);
        setNeedToEmailName(false); // When user already filed name and email not asked again
      } else {
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      console.error("Unable to update booking checkin checkout time : ", error);
    }
  };
  const options = {
    clientSecret: clientSecret,
    appearance: {
      /*...*/
    },
  };
  const closeEarlyCheckinPaymentModel = () => {
    setShowcheckinEarlyModal(false);
    setIsIntentCreate(false);
    setIsIntentModelShow(false);
    setCheckoutEmail(null); // Popup close email and name reset
    setCheckoutName(null); // Popup close email and name reset
  };

  const handleCheckoutNameChange = (event) => {
    const inputValue = event.target.value;
    setCheckoutName(inputValue);
    const isEmailValid = validateEmail(checkoutEmail);
    if (inputValue.length > 2 && isEmailValid) {
      setIsDesabled(false);
    } else {
      setIsDesabled(true);
    }
  };

  const handleCheckoutEmailChange = (event) => {
    const inputValue = event.target.value;
    setCheckoutEmail(inputValue);
    const isEmailValid = validateEmail(inputValue);
    if (checkoutName.length > 2 && isEmailValid) {
      setIsDesabled(false);
    } else {
      setIsDesabled(true);
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const renderTextWithLinks = (text) => {
    const linkRegex = /(https?:\/\/[^\s]+)/g; // Regex to match URLs
    const parts = text?.split(linkRegex);

    return parts.map((part, index) => {
      if (linkRegex.test(part)) {
        // Regex to capture trailing punctuation (like ., ?, !, etc.)
        const lastCharRegex = /[-.,=!?;:)]*$/;
        const lastCharMatch = part.match(lastCharRegex);
        // If we have trailing punctuation, isolate it
        const lastChar = lastCharMatch ? lastCharMatch[0] : "";
        // Remove the trailing punctuation from the URL
        const url = lastChar ? part.slice(0, -lastChar.length) : part;
        return (
          <>
            <a key={index} href={url} target="_blank" rel="noopener noreferrer">
              {url}
            </a>
            {lastChar}
          </>
        );
      }
      return part;
    });
  };

  /*useEffect(() => {
    let connectionString =
      "?userId=" +
      bookingSlug +
      "&userType=6&systemId=" +
      localStorage.getItem("system-id");
    let socketUrl = process.env.REACT_APP_WEB_SOCKET_URL + connectionString;
    const ws = new WebSocket(socketUrl);

    ws.onopen = () => {
      console.log("WebSocket connection opened");
      const timeInterval = 5 * 60 * 1000; // In every 5 min
      const pingInterval = setInterval(() => {
        if (ws.readyState === WebSocket.OPEN) {
          ws.send(JSON.stringify({ type: "ping" }));
        }
        console.log("WebSocket connection: ping-pong");
      }, timeInterval);

      // Clean up interval on close
      ws.onclose = () => {
        clearInterval(pingInterval);
        console.log("WebSocket connection closed");
      };
    };

    ws.onmessage = (event) => {
      const parsedMessage = JSON.parse(event?.data);
      console.log("On Message Event parsedMessage ", parsedMessage);
      if (parsedMessage.thread_slug === threadRecords?.current?.slug) {
        const newMessage = {
          slug: parsedMessage?.slug,
          message_from: parsedMessage?.message_from,
          message_from_label: parsedMessage?.message_from_label,
          created_at_time: getCurrentUTCTime(),
          message_body: parsedMessage?.message_body,
          thread_slug: threadRecords?.current?.slug,
          createDate: parsedMessage?.createDate,
        };
        let todayDate = newMessage.createDate;
        if (todayDate) {
          const updatedMessagesForDate = messageRecords.current[todayDate]
            ? [...messageRecords.current[todayDate]]
            : [];
          const messageExists = updatedMessagesForDate.some(
            (message) => message.slug === newMessage.slug
          );
          if (!messageExists) {
            updatedMessagesForDate.push(newMessage);
            messageRecords.current = {
              ...messageRecords.current,
              [todayDate]: updatedMessagesForDate,
            };
            if (newMessage?.message_from !== 1) {
              setMessageComingLoading(false);
              setSendDisable(false);
            }
            setisMsgSend(true);
            setIsNewMessage((prev) => !prev);
          }
        }
      }
    };

    ws.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    // Set the WebSocket instance to state
    setSocket(ws);
    return () => {
      ws.close();
    };
  }, []);*/

  useEffect(() => {
    let connectionString =
      "?userId=" +
      bookingSlug +
      "&userType=6&systemId=" +
      localStorage.getItem("system-id");
    let socketUrl = process.env.REACT_APP_WEB_SOCKET_URL + connectionString;

    let ws;
    let reconnectTimeout;
    let pingInterval;
    let reconnectAttempts = 0; // Track the number of reconnection attempts

    const createWebSocket = () => {
      ws = new WebSocket(socketUrl);
      ws.onopen = () => {
        reconnectAttempts = 0; // Reset reconnection attempts on successful connection
        // Send a ping every 5 minutes
        const timeInterval = 5 * 60 * 1000;
        pingInterval = setInterval(() => {
          if (ws.readyState === WebSocket.OPEN) {
            ws.send(JSON.stringify({ type: "ping" }));
          }
        }, timeInterval);
      };

      ws.onmessage = (event) => {
        const parsedMessage = JSON.parse(event?.data);
        if (parsedMessage.thread_slug === threadRecords?.current?.slug) {
          const newMessage = {
            slug: parsedMessage?.slug,
            message_from: parsedMessage?.message_from,
            message_from_label: parsedMessage?.message_from_label,
            created_at_time: getCurrentUTCTime(),
            message_body: parsedMessage?.message_body,
            thread_slug: threadRecords?.current?.slug,
            createDate: parsedMessage?.createDate,
          };
          let todayDate = newMessage.createDate;
          if (todayDate && messageRecords.current) {
            const updatedMessagesForDate = messageRecords.current[todayDate]
              ? [...messageRecords.current[todayDate]]
              : [];
            const messageExists = updatedMessagesForDate.some(
              (message) => message.slug === newMessage.slug
            );
            if (!messageExists) {
              updatedMessagesForDate.push(newMessage);
              messageRecords.current = {
                ...messageRecords.current,
                [todayDate]: updatedMessagesForDate,
              };
              setMessageComingLoading(false);
              setSendDisable(false);
              setisMsgSend(true);
              setIsNewMessage((prev) => !prev);
            }
          }
        }
      };

      ws.onerror = (error) => {
        console.error("WebSocket error:", error);
      };

      //WebSocket connection closed
      ws.onclose = () => {
        clearInterval(pingInterval);
        // Attempt to reconnect
        if (reconnectAttempts < 5) {
          // Limit the number of reconnection attempts
          reconnectTimeout = setTimeout(() => {
            reconnectAttempts++;
            createWebSocket(); // Re-establish connection
          }, 5000); // Reconnect after 5 seconds
        }
      };
      setSocket(ws);
    };

    // Initialize WebSocket connection
    createWebSocket();

    // Clean up on component unmount
    return () => {
      if (ws) {
        clearInterval(pingInterval);
        clearTimeout(reconnectTimeout);
        ws.close();
      }
    };
  }, []);

  useEffect(() => {
    // Use case: When Popup open
    if (isMsgSend && isExpanded) {
      scrollToBottom();
    }
  }, [isMsgSend, messageRecords.current, isExpanded, messageComingLoading]);

  const scrollToBottom = useCallback(() => {
    const chatContainer = chatContainerRef.current;
    if (!chatContainer) return;
    const { clientHeight, scrollHeight } = chatContainer;
    if (chatContainer) {
      chatContainer.scrollTo({
        behavior: "smooth",
        top: scrollHeight - clientHeight,
      });
    }
    if (scrollHeight <= clientHeight && fetchMessageFlag) {
      // Check if the container is not overflowing
      setPage((prevPage) => prevPage + 1);
    }
  }, [loading, messageRecords.current]);

  const closeMessageModal = async (event) => {
    document.body.classList.remove("open-host-message-body");
    messageRecords.current = null;
    setShowMessageModal(false);
    setIsNewThread(true);
    setIsExpanded(false);
    setPage(1);
    setSendDisable(false);
    setMessageComingLoading(false);
  };

  const openHostMessage = async (event) => {
    isModalClosing.current = false;
    document.body.classList.add("open-host-message-body");
    getMessages(threadRecords?.current?.slug);
    setTimeout(() => {
      setShowMessageModal(true);
    }, 500);

    if (threadRecords?.current?.unread_message_count > 0) {
      markAsRead();
    }
  };

  const createMessageThread = async (event) => {
    try {
      const formData = {
        booking_slug: bookingSlug,
        created_from: 1,
      };
      const messageThreadResult = await dispatch(
        messageActions.createMessageThread(formData)
      );

      if (messageThreadResult?.payload?.status === HttpStatus.HTTP_OK) {
        const threadData = messageThreadResult?.payload?.result;
        threadRecords.current = threadData;
        if (threadData?.is_new && !isNewThread) {
          // setTimeout(() => openHostMessage(), 1500) // delay 2 second
          openHostMessage();
        } else {
          setIsNewThread(true);
        }
      }
    } catch (error) {
      console.error("Unabe to create message thread : ", error);
    } finally {
    }
  };

  // Function to check if the text meets the required conditions
  const isValidText = (text) => {
    const emojiRegex =
      /^[\p{So}\p{Sm}\p{Sk}\p{Cf}\u200D\uFE0F\u{1F1E6}-\u{1F1FF}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FAFF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{2300}-\u{23FF}\u{2B50}\u{A9}\u{AE}\u{2122}\u{2694}-\u{26F3}\u{1F004}-\u{1F0CF}]+$/gu;
    return emojiRegex.test(text);
  };

  const postMessage = async (newFormData, images = []) => {
    setSendDisable(true);
    const { message_from, message_body, slug } = newFormData;
    const formData = {
      thread_slug: threadRecords.current?.slug,
      message_from,
      message_body,
      message_slug: slug,
      images,
    };
    try {
      const postMessageResult = await dispatch(
        messageActions.postMessage(formData)
      );
      if (postMessageResult?.payload?.status === HttpStatus.HTTP_OK) {
        const AI_enable = postMessageResult.payload.result.is_ai_enable === 1;
        const text = message_body.trim();
        const isText = isValidText(text);
        if (AI_enable && !isText) {
          startLoaderTimer();
          setMessageComingLoading(true);
          const time = getCurrentUTCTime();
          const acc_time = convertUTCToLocalTime(time);
          setCurrentTime(acc_time);
        } else {
          setSendDisable(false);
        }
      } else {
        setSendDisable(false);
        console.error(
          "Unexpected response status:",
          postMessageResult?.payload?.status
        );
      }
    } catch (error) {
      console.error("Unabe to post messages : ", error);
    } finally {
    }
  };

  const getMessages = async () => {
    setLoading(true);
    try {
      const formData = {
        thread_slug: threadRecords.current?.slug,
        per_page: perPage,
        page: page,
      };
      const messageResult = await dispatch(
        messageActions.getMessages(formData)
      );

      if (messageResult?.payload?.status !== HttpStatus.HTTP_OK) {
        setLoading(false);
        setFetchMessageFlag(false);
        return;
      }
      const messageData = messageResult?.payload?.result;
      let messageLength = Object.values(messageData).length;

      const shouldFetchMoreMessages = messageLength == perPage;

      if (page > 1) {
        messageRecords.current = {
          ...messageData,
          ...messageRecords.current,
        };
        setIsNewMessage((prev) => !prev);
      } else {
        if (!isNewThread) {
          setTimeout(() => {
            messageRecords.current = messageData;
            setIsNewThread(true);
            setisMsgSend(true);
            setIsNewMessage((prev) => !prev);
            if (!timeoutSetRef.current) {
              timeoutSetRef.current = true;
              setSendMessageAnimation("send-message-animation");
              setModalAnimation("modal-dialog-animation");
            }
          }, 2000);
          setTimeout(() => {
            setSendMessageAnimation("");
            setModalAnimation("");
          }, 2300);
        } else {
          messageRecords.current = messageData;
          setisMsgSend(true);
          // setIsNewThread(true);
          setIsNewMessage((prev) => !prev);
        }
        setFetchMessageFlag(shouldFetchMoreMessages);
      }
    } catch (error) {
      console.error("Unabe to fetch messages : ", error);
    } finally {
      setLoading(false);
    }
  };

  const threadDetails = async () => {
    try {
      const formData = {
        thread_slug: threadRecords?.current?.slug,
      };
      const threadResult = await dispatch(
        messageActions.getThreadDetails(formData)
      );

      if (threadResult?.payload?.status === HttpStatus.HTTP_OK) {
        const threadData = threadResult?.payload?.result;
        threadRecords.current = threadData;
      }
    } catch (error) {
      console.error("Unabe to fetch thread details : ", error);
    } finally {
    }
  };

  const markAsRead = async () => {
    try {
      const formData = {
        thread_slug: threadRecords?.current?.slug,
        request_from: 1,
      };
      const threadResult = await dispatch(messageActions.markAsRead(formData));
      if (threadResult?.payload?.status === HttpStatus.HTTP_OK) {
      }
    } catch (error) {
      console.error("Unabe to update mark as read : ", error);
    } finally {
    }
  };

  const handleMessageChange = (event) => {
    const messageBody = event.target.value;
    setMessageBody(messageBody);
  };

  const sendMessage = async (event) => {
    event.preventDefault();
    const trimmedMessageBody = messageBody.trim();
    if (trimmedMessageBody === "") {
      setMessageBody("");
      return;
    }
    if (inputRef.current) {
      inputRef.current.focus();
    }
    try {
      // Create a new message object
      let messageSlug = generateSlug();
      const newMessage = {
        message_from: 1,
        slug: messageSlug,
        created_at_time: getCurrentUTCTime(),
        message_body: messageBody,
        thread_slug: threadRecords?.current?.slug,
        createDate: currentDate(),
      };
      setisMsgSend(true);
      const todayDate = newMessage.createDate;
      if (!messageRecords.current[todayDate]) {
        messageRecords.current[todayDate] = [];
      }
      const messageExists = messageRecords.current[todayDate]?.some(
        (message) => message.slug === newMessage.slug
      );
      if (!messageExists) {
        const updatedMessagesForDate = messageRecords.current[todayDate]
          ? [...messageRecords.current[todayDate]]
          : [];

        updatedMessagesForDate.push(newMessage);
        messageRecords.current = {
          ...messageRecords.current,
          [todayDate]: updatedMessagesForDate,
        };
      }

      const formData = {
        slug: messageSlug,
        message_body: messageBody,
        message_from: 1,
      };
      setMessageBody("");
      await postMessage(formData);
    } catch (error) {
      console.error("Unabe to send messages", error);
    } finally {
    }
  };

  // For Message Loading Functionality (Added by Gautam)

  // useLayoutEffect to adjust scroll position immediately after DOM updates
  useLayoutEffect(() => {
    const chatContainer = chatContainerRef.current;
    if (page > 1 && chatContainer && !isMsgSend) {
      // Adjust the scroll position based on the saved scroll positions
      chatContainer.scrollTop =
        chatContainer.scrollHeight -
        scrollHeightBeforeRef.current +
        currentScrollTopRef.current -
        50;
    }
  }, [messageRecords.current, isMsgSend]); // Runs after every message update

  useEffect(() => {
    if (page > 1 && fetchMessageFlag) {
      getMessages();
    }
  }, [page]);

  // Callback function for scrolling, wrapped in useCallback to keep stable reference
  const handleScroll = useCallback(() => {
    const chatContainer = chatContainerRef.current;
    if (!chatContainer) return;
    if (chatContainer.scrollTop === 0 && fetchMessageFlag && !loading) {
      // setLoading(true);
      setisMsgSend(false);
      setPage((prevPage) => prevPage + 1);
      scrollHeightBeforeRef.current = chatContainer.scrollHeight;
      currentScrollTopRef.current = chatContainer.scrollTop;
    }
  }, [loading, fetchMessageFlag]);

  // Effect to handle scroll event when the modal is open
  useEffect(() => {
    const chatContainer = chatContainerRef.current;
    if (!chatContainer) return;
    if (showMessageModal && fetchMessageFlag) {
      chatContainer.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (chatContainer) {
        chatContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, [showMessageModal, handleScroll]); // Dependency on modal state and scroll handler

  useEffect(() => {
    if (showMessageModal) {
      setModalAnimation("modal-dialog-animation");

      // Remove the animation class after 1 second
      const timer = setTimeout(() => {
        setModalAnimation("");
      }, 300);

      return () => clearTimeout(timer); // Cleanup on unmount
    }
  }, [isExpanded, showMessageModal]);

  const handleTouchStartClose = (e) => {
    // Prevent the default touch event behavior to avoid blur firing prematurely
    isModalClosing.current = true;
    e.preventDefault();
  };

  // Callback function from child component
  const emailStatusChange = () => {
    setNeedToEmailName(false);
  }

  const getAgreementList = async (upsellPurchase = false) => {
    const agreementList = await dispatch(
      checkInActions.getAgrrements(bookingSlug)
    );

    if (agreementList?.payload?.status === HttpStatus.HTTP_OK) {
      setAgreementList(agreementList?.payload?.result);
      if(upsellPurchase){ // From upsells waiver purchased then only flag changed of isWaiver.
        setIsWaiver(1);
      }
    }
  }

  return (
    <section className="reservationnpage">
      {!pageLoaderModal && (
        <>
          <h3 className="text-center" id="reservationinfo">
            Your Arrival Instructions
          </h3>
          <div className="card-section">
            <div className="cardGreen">
              <div className="reserved">
                <div className="reservedDate">
                  <div className="checkedinDate">
                    <label>Check In</label>
                    <span className="dataLabel">
                      {isPurchased
                        ? Moment(newCheckinTime).format(global.dateTimeFormat)
                        : Moment(records?.checkin_datetime_format).format(
                            global.dateTimeFormat
                          )}
                      {/* {Moment(records?.checkin_datetime_format).format(
                    global.dateTimeFormat
                  )} */}
                    </span>
                  </div>
                  <div className="checkedinDate">
                    <label>Check Out</label>
                    <span className="dataLabel">
                      {Moment(records?.checkout_datetime_format).format(
                        global.dateTimeFormat
                      )}
                    </span>
                  </div>
                </div>
                {isPurchased && (
                  <div className="early_checkin_button">
                    <Button className="">
                      <img src={EarlyCheckinImg} />
                      Early Check In
                    </Button>
                  </div>
                )}
                <figure>
                  {records?.listing?.image ? (
                    <img src={records?.listing?.image} alt="" />
                  ) : (
                    <Skeleton duration={1} height={300} />
                  )}
                </figure>
              </div>
              <div className="content">
                {/* {isDataEnabled && ( */}
                {records?.listing?.listing_address && (
                  <>
                    <div className="checkedinDate">
                      {/* <label>{records?.listing?.listing_address}</label> */}
                      <label
                        dangerouslySetInnerHTML={{
                          __html: records?.listing?.listing_address,
                        }}
                      />
                    </div>
                  </>
                )}
                {/* {isDataEnabled ? ( */}
                {records?.listing?.listing_map ? (
                  <>
                    <div className="checkedinDate">
                      <Link to={records?.listing?.listing_map} target="_blank">
                        {" "}
                        <img src={cornerupRight} alt="" />{" "}
                      </Link>
                    </div>
                  </>
                ) : (
                  <>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: cmsContent?.address_alert,
                      }}
                    ></p>
                  </>
                )}
              </div>
            </div>

            {isAdShow && (
              <>
                <div className="cardYellow">
                  {/* <h2>{cardTitle}</h2> */}
                  <h2
                    dangerouslySetInnerHTML={{
                      __html: cardTitle,
                    }}
                  ></h2>
                  {/* <p>{cardBody}</p> */}
                  <p
                    dangerouslySetInnerHTML={{
                      __html: cardBody,
                    }}
                  ></p>

                  {isPurchased && (
                    <span className="dataLabel">
                      {Moment(newCheckinTime).format(global.dateTimeFormat)}
                    </span>
                  )}

                  {isPurchased == false && isCtaShow == true && (
                    <>
                      <div className="d-flex justify-content-center">
                        <Button
                          className="fillbutton"
                          onClick={openEarlyCheckinPaymentModel}
                        >
                          Pay to check in early
                        </Button>
                      </div>
                    </>
                  )}
                </div>
              </>
            )}

            <div className="cardBlue">
              <h2>Arrival Instructions</h2>
              {isDataEnabled ? (
                <>
                  <span
                    className="edittor_text_common_style"
                    dangerouslySetInnerHTML={{
                      __html: listingCms?.arrival_instructions,
                    }}
                  />
                  <hr className="mb-3" />
                  <h2>Wi-Fi Info</h2>
                  <div className="content">
                    <div className="checkedinDate">
                      <label>Network name</label>
                      <span className="dataLabel">{listingCms?.wifi_name}</span>
                    </div>
                    <div className="checkedinDate">
                      <label>Network password</label>
                      <span className="dataLabel">
                        {listingCms?.wifi_password}
                      </span>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: cmsContent?.arrival_alert,
                    }}
                  ></p>
                </>
              )}
            </div>

            <div className="cardGreen2">
              <h2>Local Guides</h2>
              <div className="swiperSliderone">
                <Swiper
                  onSwiper={setSwiperRef}
                  spaceBetween={5}
                  slidesPerView={1.2} // Set slidesPerView to 1.5
                  navigation={false}
                  modules={[Pagination, Navigation]}
                  className="mySwiper"
                >
                  {listingLocals &&
                    listingLocals.map((maps) => (
                      <SwiperSlide key={maps.slug}>
                        <div className="localMap">
                          <figure>
                            {maps?.image_path ? (
                              <img src={maps?.image_path} alt="" />
                            ) : (
                              <Skeleton duration={1} height={300} />
                            )}
                          </figure>
                          <div className="content">
                            <span className="dataLabel d-block w-100">
                              {maps?.title}{" "}
                            </span>
                            <span className="card-subtitle">
                              {maps?.description}{" "}
                            </span>
                            <div className="w-100 mt-3">
                              <Link to={maps?.redirect_url} target="_blank">
                                <Button className="fillbutton">
                                  {maps?.cta_title}
                                </Button>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    ))}
                </Swiper>
              </div>
            </div>
            {itemsList?.length > 0 && (
              <>
                <div className="cardGreen2 cardPink">
                  <h2>Upgrade your stay</h2>
                  <UpgradeItems 
                  itemsList={itemsList}
                  needToEmailName={needToEmailName}
                  cmsContent={cmsContent}
                  emailStatusChange={emailStatusChange}
                  getAgreementList={getAgreementList}
                  />
                </div>
              </>
            )}

            {listingCms?.house_manual && ( // It shows only if house manual is not empty
              <div className="cardBlue">
                <h2>House Manual</h2>
                <span
                    className="edittor_text_common_style"
                    dangerouslySetInnerHTML={{
                      __html: listingCms?.house_manual,
                    }}
                  />
              </div>
            )}

            <div className="cardOrange">
              <h2>Checkout Instructions</h2>
              {isDataEnabled ? (
                <>
                  <span
                    className="edittor_text_common_style"
                    dangerouslySetInnerHTML={{
                      __html: listingCms?.checkout_instructions,
                    }}
                  />
                </>
              ) : (
                <>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: cmsContent?.checkout_alert,
                    }}
                  ></p>
                </>
              )}
              <div className="d-flex justify-content-center">
                {/* {isCheckedOut === 0 && isTodayCheckedOut && ( */}
                {/* <Button className="fillbutton" onClick={markAsCheckedOut} disabled={isLoading || isCheckedOut ? true : false}>
              {isLoading ? (<><SpinnerLoader /></>) : isCheckedOut ? "I Left" : ("I'm leaving now!")}
            </Button> */}

                {/* <Button
              className="fillbutton"
              onClick={markAsCheckedOut}
              disabled={isTodayCheckedOut ? isCheckedOut ? true : false : true}
            >
              {isLoading ? (
                <>
                  <SpinnerLoader />
                </>
              ) : isCheckedOut ? (
                "I Left"
              ) : (
                isTodayCheckedOut ? "I'm leaving now!" : isTodayCheckedOut && isCheckedOut ? "I Left " : "I'm leaving now!"
              )}
            </Button> */}
              </div>
            </div>
            <div className="cardBlank">
              <figure>
                <img src={listingHost?.host_logo} alt="" />
              </figure>
              <h2>Hosted by {listingHost?.host_name}</h2>
              {/* <Button
            className="outlinebutton w-100 pb-2"
            onClick={openHostMessage}
          >
            Message
          </Button> */}
              {isWaiver === 1 && (
                <>
                  {agreementItems?.stripe_receipt_url &&
                    agreementItems?.stripe_receipt_url !== "" && (
                      <>
                        {/* <Button
                      className="outlinebutton w-100"
                      data-eventtype={1}
                      onClick={downloadReceipt}
                    >
                      Get Receipt
                    </Button> */}
                        <Link
                          to={agreementItems?.stripe_receipt_url}
                          target="_blank"
                          className="w-100"
                        >
                          <Button className="outlinebutton w-100">
                            Get <span className="text-uppercase">W</span>aiver{" "}
                            <span className="text-uppercase">R</span>eceipt
                          </Button>
                        </Link>
                      </>
                    )}
                </>
              )}
              {isPurchased && (
                <>
                  {agreementItems?.purchase_receipt_url &&
                    agreementItems?.purchase_receipt_url !== "" && (
                      <>
                        <br />
                        <Link
                          to={agreementItems?.purchase_receipt_url}
                          target="_blank"
                          className="w-100"
                        >
                          <Button className="outlinebutton w-100">
                            Get <span className="text-uppercase">E</span>arly{" "}
                            <span className="text-uppercase">C</span>heck in{" "}
                            <span className="text-uppercase">R</span>eceipt
                          </Button>
                        </Link>
                      </>
                    )}
                </>
              )}

              {isWaiver === 1 && (
                <>
                  {agreementItems?.stripe_receipt_url &&
                    agreementItems?.stripe_receipt_url !== "" &&
                    agreementItems?.waiver_agreement_url &&
                    agreementItems?.waiver_agreement_url !== "" && <br />}

                  {agreementItems?.waiver_agreement_url &&
                    agreementItems?.waiver_agreement_url !== "" && (
                      <Link
                        to={agreementItems?.waiver_agreement_url}
                        target="_blank"
                        className="w-100 mb-4"
                      >
                        <Button className="outlinebutton w-100">
                          Damage <span className="text-uppercase">W</span>aiver
                        </Button>
                      </Link>
                    )}
                  {/* {agreementItems?.rental_agreement_url &&
              agreementItems?.rental_agreement_url !== "" && (
                <Link
                  to={agreementItems?.rental_agreement_url}
                  target="_blank"
                  className="w-100"
                >
                  <Button className="outlinebutton w-100">
                    Rental <span className="text-uppercase">A</span>greement
                  </Button>
                </Link>
              )} */}
                </>
              )}
            </div>
            {isNewThread && (
              <div className="chat-ai-icon">
                <div className="chat-ai-img">
                  <img
                    src={chatIcon}
                    alt="Chat-Icon"
                    onClick={openHostMessage}
                  />
                </div>
              </div>
            )}
          </div>
        </>
      )}
      {/* Item Purchase / Early Checkin Common Model */}
      <Modal
        show={showcheckinEarlyModal}
        // onHide={() => closeEarlyCheckinPaymentModel()}
        dialogClassName="modal-90w modal-bottom"
        aria-labelledby="example-custom-modal-styling-title"
        className="earlycheckin" 
      >
        <Modal.Header
          closeButton
          onClick={() => closeEarlyCheckinPaymentModel()}
        ></Modal.Header>
        <Modal.Body>
          <div>
            <h2
              className="mb-2"
              dangerouslySetInnerHTML={{
                __html: cardTitle,
              }}
            ></h2>
          </div>
          <h6
            className="early_checkin_popup_heading"
            dangerouslySetInnerHTML={{
              __html: cardBody,
            }}
          ></h6>
          <hr className="mb-2" />
          <div className="range custom_select_drop_box mb-3">
            {!isIntentModelShow ? (
              timeInterval && timeInterval.length > 0 ? (
                <React.Fragment>
                  <span className="dataLabel3" htmlFor="hours">
                    Do you want to arrive?
                  </span>
                  <Dropdown
                    options={timeInterval}
                    onChange={handleSelectChange}
                    value={firstInterval}
                    disabled={isIntentModelShow}
                  />
                </React.Fragment>
              ) : (
                ""
              )
            ) : (
              <React.Fragment>
                <span className="dataLabel3" htmlFor="hours">
                  You want to check in early at:
                </span>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    value={selectedTimeInterval}
                    disabled={true}
                    className="input-text2-modal"
                  />
                </Form.Group>
              </React.Fragment>
            )}
          </div>
          {needToEmailName && !isIntentModelShow && (
            <React.Fragment>
              <span className="dataLabel3" htmlFor="name">
                Name
              </span>
              <Form.Group className="mb-3">
                <Form.Control
                  type="text"
                  id="name"
                  value={checkoutName}
                  onChange={handleCheckoutNameChange}
                  className="input-text2-modal"
                  required
                />
              </Form.Group>

              <span className="dataLabel3" htmlFor="email">
                Email
              </span>
              <Form.Group className="mb-3">
                <Form.Control
                  type="email"
                  id="email"
                  value={checkoutEmail}
                  onChange={handleCheckoutEmailChange}
                  className="input-text2-modal"
                />
              </Form.Group>
            </React.Fragment>
          )}
          <hr className="mb-3 mt-3" />
          {!isIntentCreate && (
            <div className="d-flex justify-content-between mb-4">
              <Button
                className="outlinebutton cancel_btn"
                onClick={() => closeEarlyCheckinPaymentModel()}
              >
                Cancel
              </Button>
              {!isFreeCheckout ? (
                <Button
                  className="fillbutton"
                  onClick={createPaymentIntent}
                  disabled={isDesabled ? true : false}
                >
                  {loader ? <SpinnerLoader /> : " Checkout"}
                </Button>
              ) : (
                <Button
                  className="fillbutton"
                  onClick={updatedCheckinCheckoutFreeTime}
                  disabled={isDesabled ? true : false}
                >
                  {loader ? <SpinnerLoader /> : " Checkout"}
                </Button>
              )}
            </div>
          )}
          {isIntentCreate && (
            <Elements
              stripe={stripePromiseForEarlyCheckin}
              options={options}
            >
              <EarlyCheckoutForm
                closeEarlyCheckinPaymentModel={closeEarlyCheckinPaymentModel}
                selectedInterval={firstInterval}
                bookingSlug={bookingSlug}
                paymentSlug={paymentSlug}
                earlyLatePurchased={earlyLatePurchased}
              />
            </Elements>
          )}
        </Modal.Body>
      </Modal>
      {/* End i have read the agreement */}

      <Modal
        show={showMessageModal}
        dialogClassName={`modal-90w modal-bottom chat-custom-modal ${modalAnimation}`}
        aria-labelledby="example-custom-modal-styling-title"
        className="message-chat-modal"
      >
        <Modal.Header>
          <div className="expend-icon" onClick={handleExpandClick}></div>
          <div
            className={`expend-body-title ${
              isExpanded ? "expandedHeader" : ""
            }`}
          >
            <h2 className="">Host Chat</h2>
          </div>
          <button
            type="button"
            className={`btn-close ${isExpanded ? "expandedCloseBtn" : ""}`}
            onTouchStart={handleTouchStartClose}
            onClick={() => closeMessageModal()}
            ref={closeButtonRef}
          >
            Close
          </button>
        </Modal.Header>
        <Modal.Body
          className={
            isExpanded
              ? `expend-chat-body ${keyboardOpen ? "open-keyboard" : ""}`
              : ""
          }
        >
          <div className="message-chat-body" ref={chatContainerRef}>
            {/* <div className="chat-card">
                  <div className="image-container">
                    <img className="first-chat-icon chat-icon-1" src={chatIcon} alt="" />
                  <div className="text-container">
                    <h6>Host Chat</h6>
                   </div>

                   <CustomMessageLoader />
                  </div>

                  <div className="text-container-1">
                    <h6>Host Chat<span>6:45 PM</span></h6>
                    <p>Hey, Ashley! Here’s your arrival info. Let me know if you need anything :)</p>
                  </div>
                </div> */}

            <div className="sender-message-box">
              {loading && (
                <div className="message-reload-box">
                  <CustomMessageLoader />
                </div>
              )}

              {messageRecords.current && isNewThread ? (
                <>
                  {Object.keys(messageRecords.current)
                    .slice(isExpanded ? 0 : -1)
                    .map((dateKey, index) => (
                      <div className="inner-chatbox" key={index}>
                        <p className="date-text text-center">
                          {dateKey === currentDate()
                            ? "Today"
                            : convertDateFormat(dateKey)}
                        </p>
                        {messageRecords.current[dateKey]
                          .slice(isExpanded ? 0 : -1)
                          .map((message, index) => (
                            <React.Fragment key={index}>
                              {/* Guest Message */}
                              {message.message_from === 1 ? (
                                <div className="guest-send-box">
                                  <div className="guest-content-box">
                                    <div className="time-text-box">
                                      <span>You</span>
                                      <span>
                                        {convertUTCToLocalTime(
                                          message.created_at_time
                                        )}
                                      </span>
                                    </div>
                                    <div
                                      className={`guest-text-message ${
                                        !isExpanded && "wrap-text-message"
                                      }`}
                                    >
                                      <p>
                                        {renderTextWithLinks(
                                          message.message_body
                                        )}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <>
                                  <div className="host-message-box">
                                    {/* <img
                                      src={listingHost?.host_logo}
                                      alt="HostLogo"
                                      onClick={openHostMessage}
                                      height={30}
                                      width={30}
                                      onError={() => setImageSrc()}
                                    /> */}
                                    <div className="host-content-box">
                                      <div className="time-text-box">
                                        <span>
                                          {message.message_from_label}
                                        </span>
                                        <span>
                                          {convertUTCToLocalTime(
                                            message.created_at_time
                                          )}
                                        </span>
                                      </div>
                                      <div
                                        className={`host-text-message ${
                                          !isExpanded && "wrap-text-message"
                                        }`}
                                      >
                                        <p>
                                          {renderTextWithLinks(
                                            message.message_body
                                          )}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}
                            </React.Fragment>
                          ))}
                      </div>
                    ))}
                  {messageComingLoading && (
                    <div className="inner-chatbox">
                      <React.Fragment>
                        <div className="host-message-box mt-20">
                          {/* <img
                            src={listingHost?.host_logo}
                            alt="HostLogo"
                            onClick={openHostMessage}
                            height={30}
                            width={30}
                            onError={() => setImageSrc()}
                          /> */}
                          <div className="host-content-box">
                            <div className="time-text-box">
                              <span className="default-message">AI Bot</span>
                              <span>{currentTime}</span>
                            </div>
                            <CustomMessageLoader />
                          </div>
                        </div>
                      </React.Fragment>
                    </div>
                  )}
                  {/* <div ref={messageEndRef} /> */}
                </>
              ) : (
                !isNewThread && (
                  <div className="inner-chatbox">
                    <React.Fragment>
                      <div className="host-message-box">
                        {/* <img
                          src={listingHost?.host_logo}
                          alt="HostLogo"
                          onClick={openHostMessage}
                          height={30}
                          width={30}
                          onError={() => setImageSrc()}
                        /> */}
                        <div className="host-content-box">
                          <div className="time-text-box">
                            <span className="default-message">Host Chat</span>
                          </div>
                          <CustomMessageLoader />
                        </div>
                      </div>
                    </React.Fragment>
                  </div>
                )
              )}
            </div>
          </div>
          {messageRecords.current && isNewThread && (
            <div className={`send-message-input-box ${sendMessageAnimation}`}>
              <Form.Group className="inner-box">
                <Form.Control
                  type="text"
                  id="message"
                  value={messageBody}
                  onChange={handleMessageChange}
                  className="input-text2-modal"
                  required
                  onFocus={(e) => handleExpandClick(e, true)}
                  onBlur={handleBlur}
                  placeholder="Type your message..."
                  ref={inputRef}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      sendMessage(e);
                    }
                  }}
                />
                <Button
                  className="outlinebutton"
                  onClick={sendMessage}
                  disabled={sendDisable}
                >
                  <img src={sendMessageIcon} alt="Message-icon" />
                </Button>
              </Form.Group>
            </div>
          )}
        </Modal.Body>
      </Modal>

      {/* Loader modalbox */}
      <Modal
        show={pageLoaderModal}
        // onHide={() => setshowIdCardModal(false)}
        dialogClassName="modal-90w modal-bottom"
        aria-labelledby="example-custom-modal-styling-title"
        className="pageloaderBox"
      >
        <Modal.Body>
          <div className="loaderContent">
            <h1 className="spinnerText">
              <span className="spintextone d-block mb-3"></span>
              <span className="spintexttwo d-block"></span>
            </h1>
          </div>
        </Modal.Body>

        <div className="welcomebgvideomodal">
          <video autoPlay muted playsInline loop id="video">
            <source
              src={
                process.env.REACT_APP_AWS_CLOUD_FRONT_URL +
                "/Stream_Videos/welcome-bg.mp4"
              }
              type="video/mp4"
            />
          </video>
        </div>
        <div className="welcomebgvideomodalmobile">
          <video autoPlay muted playsInline loop id="video">
            <source
              src={
                process.env.REACT_APP_AWS_CLOUD_FRONT_URL +
                "/Stream_Videos/welcome-bg.mp4"
              }
              type="video/mp4"
            />
          </video>
        </div>
      </Modal>
      {/* End Loader modalbox */}
    </section>
  );
}
export default Reservationinfo;
