import React from "react";
import './MessageLoader.css';

function CustomMessageLoader() {
    return (
        <>
        <div className="typing-indicator">
            <span></span>
            <span></span>
            <span></span>
        </div>
        </>
    )
}

export default CustomMessageLoader;